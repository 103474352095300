<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.show" />

    <div v-else>
      <b-card no-body class="card-developer-meetup">
        <b-card-body>
          <b-row>
            <b-col md="4">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="UserIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">{{ $t("g.name") }}</h5>
                  <h5 class="mb-0">{{ data_client.name }}</h5>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="4">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="MailIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">
                    {{ $t("g.email") }}
                  </h5>
                  <h5 v-if="data_client.email" class="mb-0">
                    {{ data_client.email }}
                  </h5>
                </b-media-body>
              </b-media>
            </b-col>
            <b-col md="4">
              <b-media no-body class="">
                <b-media-aside>
                  <b-avatar rounded variant="light-primary" size="35">
                    <feather-icon icon="PhoneIcon" size="18" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h5 class="mb-0 text-primary">{{ $t("g.phoneNumber") }}</h5>
                  <h5 class="mb-0" v-if="data_client">
                    {{ data_client.phone ? data_client.phone.phone : $t('g.there is no phone number') }}
                  </h5>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-card>
        <div class="custom-search d-flex justify-content-between mb-1">
          <!-- search input -->
          <b-form-group>
            <div class="d-flex align-items-center">
              <div>
                <label class="mr-1">{{ $t("g.searchLabel") }}</label>
                <b-form-input
                  v-model="searchTerm"
                  :placeholder="$t('g.searchHere')"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          styleClass="vgt-table condensed"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <!-- Column: Name -->
            <span
              v-if="props.column.label === 'prodcut_name'"
              class="text-nowrap"
            >
              {{ $t("g.productName") }}
            </span>

            <span
              v-if="props.column.label === 'receive_date'"
              class="text-nowrap"
            >
              {{ $t("g.receiveDate") }}
            </span>
            <span
              v-if="props.column.label === 'review_action_date'"
              class="text-nowrap"
            >
              {{ $t("g.reviewActionDate") }}
            </span>
            <span
              v-if="props.column.label === 'sent_for_final_review_date'"
              class="text-nowrap"
            >
              {{ $t("g.sentForFinalReviewDate") }}
            </span>
            <span
              v-if="props.column.label === 'sent_for_tech_date'"
              class="text-nowrap"
            >
              {{ $t("g.sentForTechDate") }}
            </span>
            <span
              v-if="props.column.label === 'sent_for_tech_review_date'"
              class="text-nowrap"
            >
              {{ $t("g.sentForTechReviewDate") }}
            </span>
            <span
              v-if="props.column.label === 'service_code'"
              class="text-nowrap"
            >
              {{ $t("g.serviceCode") }}
            </span>
            <span
              v-if="props.column.label === 'submit_date'"
              class="text-nowrap"
            >
              {{ $t("g.submitDate") }}
            </span>
            <span
              v-if="props.column.label === 'tech_review_action_date'"
              class="text-nowrap"
            >
              {{ $t("g.techReviewActionDate") }}
            </span>
            <span v-if="props.column.label === 'status'" class="text-nowrap">
              {{ $t("g.status") }}
            </span>
            <span
              v-if="props.column.label === 'is_tech_review_accepted'"
              class="text-nowrap"
            >
              {{ $t("g.isTechReviewAccepted") }}
            </span>
            <span
              v-if="props.column.label === 'is_reviewed'"
              class="text-nowrap"
            >
              {{ $t("g.isReviewed") }}
            </span>
            <span
              v-if="props.column.label === 'is_review_accepted'"
              class="text-nowrap"
            >
              {{ $t("g.isReviewAccepted") }}
            </span>
            <span
              v-if="props.column.label === 'is_final_review_accepted'"
              class="text-nowrap"
            >
              {{ $t("g.isFinalReviewAccepted") }}
            </span>
            <span
              v-if="props.column.label === 'final_review_action_date'"
              class="text-nowrap"
            >
              {{ $t("g.finalReviewActionDate") }}
            </span>
            <span
              v-if="props.column.label === 'excpected_review_end_date'"
              class="text-nowrap"
            >
              {{ $t("g.expectedReviewEndDate") }}
            </span>
            <span
              v-if="props.column.label === 'closed_date'"
              class="text-nowrap"
            >
              {{ $t("g.closedDate") }}
            </span>
          </template>

          <!-- Slot: Table Row -->

          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'receive_date_row'">
              {{ formatDate(props.row.receive_date) }}
            </span>
            <span v-if="props.column.field === 'review_action_date_row'">
              {{ formatDate(props.row.review_action_date) }}
            </span>
            <span v-if="props.column.field === 'sent_for_tech_date_row'">
              {{ formatDate(props.row.sent_for_tech_date) }}
            </span>
            <span v-if="props.column.field === 'sent_for_tech_review_date_row'">
              {{ formatDate(props.row.sent_for_tech_review_date) }}
            </span>
            <span v-if="props.column.field === 'submit_date_row'">
              {{ formatDate(props.row.submit_date) }}
            </span>
            <span v-if="props.column.field === 'tech_review_action_date_row'">
              {{ formatDate(props.row.tech_review_action_date) }}
            </span>
            <span v-if="props.column.field === 'final_review_action_date_row'">
              {{ formatDate(props.row.final_review_action_date) }}
            </span>
            <span v-if="props.column.field === 'excpected_review_end_date_row'">
              {{ formatDate(props.row.excpected_review_end_date) }}
            </span>
            <span v-if="props.column.field === 'closed_date_row'">
              {{ formatDate(props.row.closed_date) }}
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  {{ $t("g.pagelength") }}
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '20']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  {{ $t("g.of") }} {{ props.total }}
                  {{ $t("g.pageText") }}
                </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BCardBody,
  BRow,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BMedia,
  BFormInput,
  BPagination,
  BFormGroup,
  BFormSelect,
  VBTooltip,
} from "bootstrap-vue";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
// import dateTimeInFormat from "@/libs/format-date";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ContentNotView from "@/views/components/logic/contentNotView.vue";
import dateTimeInFormat from "@/composables/format-date/format-date";

export default {
  components: {
    Loading,
    Error,
    BCard,
    BCol,
    BCardBody,
    BRow,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BMedia,
    BFormInput,
    BPagination,
    BFormGroup,
    BFormSelect,
    VueGoodTable,
    ContentNotView,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data_client: [],
      currentId: null,
      isLoading: true,
      isError: null,
      formatDate: dateTimeInFormat,
      log: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: "prodcut_name",
          field: "prodcut_name",
        },
        {
          label: "receive_date",
          field: "receive_date_row",
        },
        {
          label: "review_action_date",
          field: "review_action_date_row",
        },
        {
          label: "sent_for_final_review_date",
          field: "sent_for_final_review_date_row",
        },

        {
          label: "sent_for_tech_date",
          field: "sent_for_tech_date_row",
        },
        {
          label: "sent_for_tech_review_date",
          field: "sent_for_tech_review_date_row",
        },
        {
          label: "service_code",
          field: "service_code",
        },
        {
          label: "submit_date",
          field: "submit_date_row",
        },

        {
          label: "tech_review_action_date",
          field: "tech_review_action_date_row",
        },
        {
          label: "status",
          field: "stauts",
        },
        {
          label: "is_tech_review_accepted",
          field: "is_tech_review_accepted",
        },
        {
          label: "is_reviewed",
          field: "is_reviewed",
        },
        {
          label: "is_review_accepted",
          field: "is_review_accepted",
        },
        {
          label: "is_final_review_accepted",
          field: "is_final_review_accepted",
        },
        {
          label: "final_review_action_date",
          field: "final_review_action_date_row",
        },
        {
          label: "excpected_review_end_date",
          field: "excpected_review_end_date_row",
        },
        {
          label: "closed_date",
          field: "closed_date_row",
        },
      ],
      rows: [],
      searchTerm: "",
      subPermission: {},
    };
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  beforeMount() {
    this.currentId = this.$route.params.id;

    this.$http
      .get(`admin/clients/${this.currentId}`)
      .then((response) => {
        if (response.status === 200) {
          
          const data = response.data.data;
          this.data_client = {
            name: data.name,
            phone: data.phone,
            email: data.email,
          };
          this.rows = data.service_requests;
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "clients");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error.message);
        this.isLoading = false;
        this.isError = true;
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
